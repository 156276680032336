.el-breadcrumb[data-v-3bb97db2] {
  font-size: var(--rootFontSize2);
  line-height: 40px;
}
.el-breadcrumb[data-v-3bb97db2] .el-breadcrumb__inner {
    font-weight: bold;
    color: #0270c1;
}
.el-breadcrumb[data-v-3bb97db2] .el-breadcrumb__inner:hover {
      font-weight: bold;
      color: #0270c1;
}
